<template>
  <SimpleTableForm
    :id="id"
    title="Create Transportation Distance Option"
    :table-config="tableConfig"
    :return-route="adminRoute"
    :server-type="serverType"
    @created="$emit('created')"
  />
</template>

<script>

import SimpleTableForm from '@/components/form/SimpleTableForm'
import { transportationDistanceOptionsForm } from './constants'

export default {
  name: 'TransportationDistanceOptionForm',
  namespaced: true,
  components: { SimpleTableForm },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      tableConfig: transportationDistanceOptionsForm,
      serverType: 'transportation_distance_options',
      adminRoute: '/admin/transportationDistanceOptions'
    }
  }
}
</script>
