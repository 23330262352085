<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="transportationDistanceOptions"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="headline pb-0 pt-2">
            Transportation Distance Options
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="showForm"
            max-width="500"
          >
            <template #activator="{ on }">
              <v-btn
                color="shamrock"
                dark
                class="mb-2"
                elevation="0"
                v-on="on"
              >
                New Transportation Distance Option
              </v-btn>
            </template>
            <TransportationDistanceOptionForm
              @created="handleCreatedTransportationDistanceOption"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <EditAndDeleteButtons
          :edit-href="`/admin/transportationDistanceOptions/${item._id}`"
          @delete="deleteTransportationDistanceOption(item._id)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import TransportationDistanceOptionForm from './TransportationDistanceOptionForm'
import { mapActions } from 'vuex'
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'

export default {
  name: 'AdminTransportationDistanceOptions',
  components: {
    TransportationDistanceOptionForm,
    EditAndDeleteButtons
  },
  data () {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Modifier ID',
          value: 'modifierId'
        },
        {
          text: 'Distance (km)',
          value: 'distance'
        },
        {
          text: 'Notes',
          value: 'notes'
        },
        {
          value: 'actions',
          sortable: false
        }
      ],
      transportationDistanceOptions: [],
      showForm: false
    }
  },
  created () {
    this.fetchTransportationDistanceOptions()
  },
  methods: {
    ...mapActions(['showSnackbar']),

    async fetchTransportationDistanceOptions () {
      const { data: transportationDistanceOptions } = await this.$axios.get('/transportation_distance_options')
      this.transportationDistanceOptions = transportationDistanceOptions.map(transportationDistanceOption => ({
        ...transportationDistanceOption
      }))
    },
    async deleteTransportationDistanceOption (id) {
      try {
        await this.$axios.delete(`/transportation_distance_options/${id}`)
        await this.fetchTransportationDistanceOptions()
      } catch (err) {
        this.showSnackbar({ color: 'error', message: 'Unable to delete transportation distance option. Please try again.' })
        throw err
      }
    },
    handleCreatedTransportationDistanceOption () {
      this.showForm = false
      this.fetchTransportationDistanceOptions()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
