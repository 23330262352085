<template>
  <div style="text-align:right !important">
    <v-btn
      v-if="!hideEdit"
      small
      text
      color="shamrock"
      :href="editHref || undefined"
      @click.stop="$emit('edit')"
    >
      <v-icon
        left
        small
      >
        mdi-pencil
      </v-icon>
      {{ editText }}
    </v-btn>
    <v-btn
      small
      text
      color="crimson"
      @click.stop="$emit('delete')"
    >
      <v-icon
        left
        small
      >
        mdi-delete
      </v-icon>
      Delete
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'EditAndDeleteButtons',
  props: {
    editHref: {
      type: String,
      default: null
    },
    editText: {
      type: String,
      default: 'Edit'
    },
    hideEdit: {
      type: Boolean,
      default: false
    }
  }
}

</script>
