export const transportationDistanceOptionsFields = [
  {
    name: 'modifierId',
    label: 'Modifier Id',
    rules: 'required',
    prefix: 't'
  },
  {
    name: 'distance',
    label: 'Distance',
    rules: 'required',
    suffix: 'km',
    prefix: 't'
  }
]
export const transportationDistanceOptionsForm = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required'
  },
  ...transportationDistanceOptionsFields,
  {
    name: 'notes',
    label: 'Notes'

  }
]

export const elementTypeHasField = (element) => {
  return element.cpdVersion === 3 && element.type !== 'hydrozone'
}
